<template>
  <div class="order-detail-content clearfix">
    <div class="main-content clearfix">
      <div class="left-content">
        <div class="content-block detail-maininfo">
          <!--    基本信息      -->
          <order-info :info="detailObj"></order-info>
        </div>
        <div  class="content-block  form-content">
          <div class="form-group_item width100">
            <label class="nowrap">照片/视频：</label>
            <resources :source="detailObj.descriptionResources" :multiple-preview="true"></resources>
          </div>
          <!--    处理方法      -->
          <template v-if="detailObj.status>2">
            <el-divider></el-divider>
            <div class="form-content">
              <div class="form-group_item width100">
                <label>处理方法：</label>
                <div class="description">{{detailObj.handleDescription||'无'}}</div>
              </div>
              <div class="form-group_item width100">
                <label>图片/视频：</label>
                <resources :source="detailObj.handleResources" :multiple-preview="true"></resources>
              </div>
            </div>
          </template>

          <!--     评价         -->
          <template v-if="detailObj.status==4">
            <el-divider></el-divider>
            <div class="block-tag">评价</div>
            <div class="form-content rate">
              <div class="rate_item">
                <label>服务态度</label>
                <el-rate v-model="detailObj.scoreAttitude+3"  disabled text-color="#ff9900"></el-rate>
              </div>
              <div class="rate_item">
                <label>解决速度</label>
                <el-rate v-model="detailObj.scoreSolveSpeed+3" disabled text-color="#ff9900"></el-rate>
              </div>
              <div class="rate_item">
                <label>解决质量</label>
                <el-rate v-model="detailObj.scoreSolveQuality+3"  disabled text-color="#ff9900"></el-rate>
              </div>
              <div class="rate_item">
                <label>描述</label>
                <div class="description">{{detailObj.evaluateDescription||'无'}}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="diliver"></div>
      <div class="right-content">
        <h3>工单流程</h3>
        <order-flow :tourNodes="nodes" :detail-id="detailObj.id" :detail-status="detailObj.status" @refreshNode="$emit('refreshNode')"></order-flow>
      </div>
    </div>
  </div>
</template>

<script>
import OrderInfo from "@/pages/tour-module/components/order-info";
import OrderFlow from "@/pages/tour-module/components/order-flow";
import Resources from "@/pages/propertyModule/components/resources";
export default {
  name: "tour-order-detail",
  components: {Resources, OrderFlow, OrderInfo},
  props:{
    detailObj:{
      type:Object,
      default(){
        return {}
      }
    },
    nodes:{
      type:Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/pages/propertyModule/style/detail";
</style>