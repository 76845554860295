<template>
  <div class="comment-item">
    <!-- 第一层都是评论 -->
    <div class="item_one">
      <div class="item-one-name" @click="reply">
        <span class="item_one__username">
          <span v-if="one.commentUser">{{one.createUser.username}} <span class="colorBlack">回复</span> {{one.commentUser.username}}：</span>
          <span v-else>{{one.createUser.username}}：</span>
        </span>
        <span>{{one.content}}</span>
      </div>
      <div class="item_one__resource">
        <resources class="image" :source="one.contentResource" :multiple-preview="true"></resources>
      </div>
      <div class="colorGrey">{{one.createTime||'2021-03-10 11:00:20'}}</div>
      <div v-for="item in one.child" :key="item.id" class="item_one__child">
        <comment-one :one="item" :order-id="orderId" :order-status="orderStatus" v-on="$listeners"></comment-one>
      </div>
    </div>
  </div>

</template>

<script>
import Resources from "@/pages/propertyModule/components/resources";
export default {
  name: "comment-one",
  components: {Resources},
  props:['one','orderId','orderStatus'],
  methods:{
    reply(){
      if(this.orderStatus==2||this.orderStatus==4) return
      this.$emit('reply',this.one)
    }
  }
}
</script>

<style scoped lang="scss">
.comment-item{
  margin: 8px;
  .item_one{
    .item-one-name{
      cursor: pointer;
      line-height: 1.2;
    }
    .item_one__username{
      color:#5D68FD;
    }
    .item_one__resource{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .image{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      &:after{
        content: '';
        width: 0%;
        height: 0;
        overflow: hidden;
      }
    }

    .item_one__child{
      margin-left: 12px;
    }
  }
}
::v-deep .image .img-group{
  padding: 0 !important;

}
::v-deep .image .img-group img{
  width: 60px;
  height: 60px;
}

</style>