<template>
  <div>
    <tour-order-detail :detail-obj="detail" :nodes="tourNodes" @refreshNode="getNodes(id)"></tour-order-detail>
    <div class="occupy-ele"></div>
    <div class="fixed-bottom-btn">
      <el-row>
        <el-button class="btn-default" size="medium" @click="back">返回</el-button>
        <template  v-if="detail.status==1&&from">
          <!--   我发起的列表 有权限的按钮：催办，评价       -->
          <!--   我受理的列表 有权限的按钮：移交，解决       -->
          <!--   我关注的列表 有权限的按钮：无      -->

          <!--    处理中可以催办 催办间隔是2分钟     -->
          <el-button class="btn-primary" type="primary" size="medium" @click="urge"
                     v-if="from=='sponsor'">催办</el-button>
          <el-button v-if="from=='accept'&&detail.handoverType==0" class="btn-primary" type="primary" size="medium" @click="handover">移交</el-button>
          <el-button v-if="from=='accept'&&isHandler" class="btn-primary" type="primary" size="medium" @click="solve">解决</el-button>
        </template>
        <el-button v-if="detail.status===3 && from=='sponsor'" class="btn-primary" type="primary" size="medium" @click="evaluate">评价</el-button>
      </el-row>
    </div>

    <!-- 移交工单   -->
    <el-dialog title="移交工单" :visible.sync="handoverDialogShow">
      <el-form :model="handoverForm" ref="handoverForm" :rules="handoverFormRules" label-width="110px">
        <el-form-item label="受理部门/人" prop="selectUser">
          <user-and-dept-cascader v-model="handoverForm.selectUser" :filter-shop="true"></user-and-dept-cascader>
        </el-form-item>
        <el-form-item label="移交原因">
          <el-input type="textarea" maxlength="80"
                    v-model="handoverForm.reason">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" size="medium" @click="handoverDialogShow=false">取消</el-button>
        <el-button type="primary" size="medium" @click="confirmHandover">确定</el-button>
      </div>
    </el-dialog>

    <!--  解决工单  -->
    <el-dialog title="解决" :visible.sync="solveDialogShow">
      <el-form :model="solveForm" ref="solveForm" :rules="solveFormRules" size="medium" label-width="110px">
        <el-form-item label="图片/视频" prop="handleResources">
          <d-upload :file-list.sync="solveForm.handleResources" :limit="10" :multiple-preview="true"></d-upload>
        </el-form-item>
        <el-form-item label="处理方法">
          <el-input type="textarea" maxlength="80"
                    v-model="solveForm.handleDescription">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" size="medium" @click="solveDialogShow=false">取消</el-button>
        <el-button type="primary" size="medium" @click="confirmSolve">确定</el-button>
      </div>
    </el-dialog>

    <!--  工单评价  -->
    <el-dialog title="评价" :visible.sync="evaluateDialogShow" >
      <el-form :model="evaluateForm" ref="evaluateForm" :rules="evaluateFormRules" label-width="110px">
        <el-form-item label="服务态度" prop="scoreAttitude">
          <el-rate v-model="evaluateForm.scoreAttitude" text-color="#ff9900"></el-rate>
        </el-form-item>
        <el-form-item label="解决速度" prop="scoreSolveSpeed">
          <el-rate v-model="evaluateForm.scoreSolveSpeed" text-color="#ff9900"></el-rate>
        </el-form-item>
        <el-form-item label="解决质量" prop="scoreSolveQuality">
          <el-rate v-model="evaluateForm.scoreSolveQuality" text-color="#ff9900"></el-rate>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" maxlength="80"
                    v-model="evaluateForm.reason">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" size="medium" @click="evaluateDialogShow=false">取消</el-button>
        <el-button type="primary" size="medium" @click="confirmEvaluate">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TourOrderDetail from "@/pages/tour-module/components/tour-order-detail";
import UserAndDeptCascader from "@/components/userAndDeptCascader";
import Resources from "@/pages/propertyModule/components/resources";
import DUpload from "@/components/d-upload";
export default {
  name: "tour-detail",
  components: {DUpload, Resources, UserAndDeptCascader, TourOrderDetail},
  data(){
    let validateFile = (rule,value,callback)=>{
      if(this.solveForm.handleResources.length===0){
        callback(new Error('不能为空'))
      }else{
        callback()
      }
    }
    let validateZero = (rule,value,callback)=>{
      if(value===0){
        callback(new Error('不能为空'))
      }else{
        callback()
      }
    }
    return {
      id:Number(this.$route.query.id),
      from:'',
      isHandler:false,
      isSelf:false,
      detail:{},
      tourNodes:[],
      handoverDialogShow:false,
      handoverForm:{
        selectUser:'',
        reason:''
      },
      handoverFormRules:{
        selectUser:this.$formValidator.empty('不能为空',['change','blur'],'string')
      },

      solveDialogShow:false,
      solveForm:{
        handleResources:[],
        handleDescription:''
      },
      solveFormRules:{
        handleResources:[{required:true,validator:validateFile,trigger:['blur','change']}]
      },

      evaluateDialogShow:false,
      evaluateForm:{
        scoreAttitude:0,
        scoreSolveQuality:0,
        scoreSolveSpeed:0,
        reason:''
      },
      evaluateFormRules:{
        scoreAttitude:[{required:true,validator:validateZero,trigger:'change'}],
        scoreSolveQuality:[{required:true,validator:validateZero,trigger:'change'}],
        scoreSolveSpeed:[{required:true,validator:validateZero,trigger:'change'}],
      }
    }
  },
  mounted(){
    this.getDetail(this.id);
    this.getNodes(this.id);
    if(this.$route.query.needRead==='true'){
      this.readOrder(this.id)
    }
    this.from = this.$route.query.from
  },
  methods:{
    back(){
      if(this.from){
        this.$router.replace({path:'/tourModule/management/workorder/my',query:{activeTab:this.from}})
      }else{
        this.$router.go(-1);
      }
    },

    getDetail(id){
      this.$api.tour.orderDetail({id}).then(res=>{
        this.detail = res.data;
        this.isSelf = this.$reconava.isCreatedByCurrentUser(res.data.createUser)
        this.getHandler(res.data)
      })
    },

    getNodes(id){
      this.$api.tour.orderNodes({id}).then( async ({data})=>{
        this.tourNodes = await Promise.all(data.map(async e=>{
          const {data} =  await this.$api.tour.getCommentList({nodeDetailId:e.id,pageNum:1,pageSize:5});
          e._showMore = data && data.total>5
          e.commentList = data?data.list:[];
          return e;
        }))
      })
    },

    readOrder(id){
      this.$api.tour.readOrder([id]).then(()=>{

      })
    },

    getHandler(detailData){
      // 当前用户的id
      const {id}  = JSON.parse(localStorage.getItem('userInfo'))
      let deptId;
      // 获取当前用户的deptId
      this.$api.sysapi.getUserInfo(id).then(({data})=>{
        deptId = data.deptIds[0];
        let result = false;
        if(detailData.handleType==2 && detailData.handleDept && (detailData.handleDept.id === deptId)){
          result  = true;
        }else if(detailData.handleType==1 && detailData.handleUser && (detailData.handleUser.id === id)){
          result  = true;
        }
        this.isHandler = result
      })
    },

    //  催办
    urge(){
      this.$api.tour.urgeOrder({id:this.id}).then(()=>{
        this.$message.success('催单成功')
        this.getDetail(this.id)
      })
    },

    // 移交
    handover(){
      this.handoverDialogShow = true;
      this.$refs.handoverForm && this.$refs.handoverForm.clearValidate();
      this.handoverForm = {
        selectUser:'',
        reason:''
      }
    },

    // 移交
    confirmHandover(){
      this.$refs.handoverForm.validate((valid)=>{
        if(valid){
          let param = {
            id:this.id,
            reason: this.handoverForm.reason
          }
          ~(this.handoverForm.selectUser.toString().indexOf('d-'))?
              param.handleDeptId = this.handoverForm.selectUser.substr(2):
              param.handleUserId = this.handoverForm.selectUser;
          this.$api.tour.handoverOrder(param).then(()=>{
            this.$message.success('工单已移交');
            this.back()
          })
        }
      })
    },

     // 解决
    solve(){
      this.solveDialogShow = true;
      this.$refs.solveForm && this.$refs.solveForm.clearValidate();
      this.solveForm = {
        handleResources:[],
        handleDescription:''
      }
    },

    confirmSolve(){
      this.$refs.solveForm.validate((valid)=>{
        if(valid){
          this.$api.tour.solveOrder({...this.solveForm,id:this.id}).then(()=>{
            this.$message.success('工单已解决');
            this.back();
          })
        }
      })
    },

    // 评价
    evaluate(){
      this.evaluateDialogShow = true;
      this.evaluateForm = {
        scoreAttitude:0,
        scoreSolveQuality:0,
        scoreSolveSpeed:0,
        reason:''
      }
    },

    confirmEvaluate(){
      this.$refs.evaluateForm.validate((valid)=>{
        if(valid){
          let param = {
            scoreAttitude:Number(this.evaluateForm.scoreAttitude)-3,
            scoreSolveQuality:Number(this.evaluateForm.scoreSolveQuality)-3,
            scoreSolveSpeed:Number(this.evaluateForm.scoreSolveSpeed)-3,
            reason:this.evaluateForm.reason,
            id:this.id
          }
          this.$api.tour.evaluateOrder(param).then(()=>{
            this.$message.success('工单已评价');
            this.evaluateDialogShow = false;
            this.getDetail(this.id);
          })
        }})
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-rate__icon{
  font-size: 24px;
}
::v-deep .el-input,.el-select,.el-textarea,.el-cascader{
  width: 400px;
}
</style>