<template>
  <div class="flow-content">
    <el-timeline class="node-block">
      <el-timeline-item
          placement="bottom"
          v-for="(value,index) in tourNodes" :key="index"
          type="primary"
          :class="[value.nodeType==0&&detailStatus==2?'repeal':getNodeStatus(value.dealType)]"
      >
        <div class="timeline-nodeName clearfix">
          <div class="handover-tag" v-if="value.handoverType>0">
            <img class="handover-tag_icon" mode="widthFix" src="@/assets/handover.png"/>
            <span class="handover-tag_title">移交</span>
          </div>
          <span class="fontBold">{{value.nodeName}}</span>
          <span class="comment-btn" v-if="detailStatus!=2&&detailStatus!=4&&!$route.query.canEdit" @click="commentNode(0,value.id,value)">回复</span>
        </div>
        <div class="timeline-approver">
          <div class="timeline-time" v-if="value.updateTime">{{value.updateTime}}</div>
          <div class="timeline-handover" v-if="value.handoverType">{{`移交 ->  ${value.handoverType==1?value.handoverUser && value.handoverUser.username:value.handoverDept && value.handoverDept.deptName}`}}</div>
          <span  v-if="value.dealRemark">{{value.dealRemark}}</span>
          <div class="timeline-reason" v-if="value.dealReason.trim()">
            {{value.dealReason}}
          </div>
          <div v-if="value.nodeType==0&&detailStatus==2">已撤销</div>
        </div>
        <div class="flow_comment" v-if="value.commentList.length">
          <div v-for="commentOne in value.commentList"
               :key="commentOne.id"
               class="flow_comment_one">
            <comment-one :one="commentOne" :order-id="detailId" :order-status="detailStatus" @reply="(item)=>commentNode(commentOne.id,value.id,item)"></comment-one>
          </div>
        </div>
        <div class="textCenter" style="cursor: pointer" v-if="value._showMore" @click="showMore(value.id,index)">查看更多评论</div>
      </el-timeline-item>
    </el-timeline>
    <flow-comment :current="currentCommentOne"
                  :node-id="nodeId"
                  :top-id="topId"
                  :order-id="detailId"
                  :show.sync="showComment"
                  @refresh="refresh"></flow-comment>
    <el-drawer style="z-index:2015" title="评论详情" :visible.sync="isShowDetail" direction="rtl" :modal="false" modal-append-to-body>
      <div class="comment-detail" v-if="isShowDetail&&nodeAllComment.length">
        <div v-for="commentOne in nodeAllComment"
             :key="commentOne.id"
             class="flow_comment_one">
          <comment-one :one="commentOne" :order-id="detailId" :order-status="detailStatus" @reply="(item)=>commentNode(commentOne.id,tourNodes[detailIndex].id,item)"></comment-one>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import CommentOne from "@/pages/tour-module/components/comment-one";
import FlowComment from "./flow-comment";
import {deepClone} from "@/components/dynamicForm/utils/utils";
import {flattenList} from "../../../utils/utils";
export default {
  name: "order-flow",
  components: {FlowComment, CommentOne},
  props:['tourNodes','detailId','detailStatus'],
  data(){
    return {
      showComment:false,
      topId:0,
      nodeId:0,
      currentCommentOne:null,
      isShowDetail:false,
      detailIndex:0,
      nodeAllComment:[]
    }
  },
  methods:{
    getNodeStatus(dealType){
      switch (dealType) {
        case 0:
          return 'approve';
          break;
        case 1:
          return 'refuse';
          break;
        default:
          return 'default';
          break;
      }
    },

    commentNode(topId,nodeId,item){
        this.showComment = true;
        this.topId = topId;
        this.nodeId = nodeId;
        this.currentCommentOne = deepClone(item)
    },

    showMore(nodeId,index){
      this.detailIndex = index;
      this.isShowDetail = true;
      this.$api.tour.getCommentList({nodeDetailId:nodeId,pageNum:1,pageSize:-1}).then(({data})=>{
        this.nodeAllComment = data.list;
      })
    },

    refresh(){
      this.$emit('refreshNode');
      if(this.isShowDetail){
        this.$api.tour.getCommentList({nodeDetailId:this.tourNodes[this.detailIndex].id,pageNum:1,pageSize:-1}).then(({data})=>{
          this.nodeAllComment = data.list;
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/pages/propertyModule/style/detail-flow";
.handover-tag{
  display: inline-flex;
  justify-content: center;
  margin-right: 6px;

  .handover-tag_icon{
    width: 15px;
    margin-right: 5px;
  }

  .handover-tag_title{
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }

}
.timeline-handover{
  color: $subjectColor;
  margin-bottom: 5px;
}

.comment-btn{
  font-size: 12px;
  border-radius: 15px;
  padding: 4px 12px;
  color: #fff;
  background: #BBBAFF;
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
}

.flow_comment{
  border:1px dashed  #c0c0c0;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
  max-height: 250px;
  overflow: auto;

  .flow_comment_one{
    margin-bottom: 10px;
    position: relative;
    margin-left: 5px;
  }
}
::v-deep .el-drawer__body{
  overflow: auto;
}
.comment-detail{
  padding: 20px;
}


</style>