<template>
  <div>
    <div class="img-group">
      <template v-for="(imgitr) in source">
          <img :key="imgitr.url" alt="" title="视频,单击播放" class="resource-item"
              :src="imgitr.url+'?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto'"
              @click="playVideo(imgitr)" v-if="['.mp4','.mov','.m4v','.3gp','.m3u8','.webm'].includes(imgitr.url.substring(imgitr.url.lastIndexOf('.')))">
          <img :key="imgitr.url" v-else :src="imgitr.url" alt="" title="单击预览" class="resource-item" @click="handlePreview(imgitr)">
      </template>
    </div>
    <div class="video-wrapper" v-if="playVideoSrc"  @click="closeVideo">
      <video id="video-play" class="video" autoplay controls="controls">
        <source :src="playVideoSrc" :type="'video/'+playVideoSrc.substring(playVideoSrc.lastIndexOf('.')+1)">
      </video>
    </div>
    <viewer v-if="currentViewPhotos.length" ref="previewFileRef" class="file-content" :images="currentViewPhotos">
      <img v-for="(src)  in currentViewPhotos" :src="src" :key="src" alt="" class="resource-item">
    </viewer>
  </div>

</template>

<script>
import uploadCommon from "@/utils/upload";
export default {
  name: "resources",
  props:{
    source:{
      type:Array,
      default(){
        return []
      }
    },
    multiplePreview:{
      type:Boolean,
      default(){
        return false
      }
    }
  },
  data(){
    return {
      playVideoSrc:'',
      currentViewPhotos: []
    }
  },
  methods:{
    playVideo(file){
      this.playVideoSrc = file.url;

    },

    // 预览图片
    handlePreview(currentFile){
      let currentIndex=0;
      if(this.multiplePreview){
        this.currentViewPhotos = this.source.filter(e=> uploadCommon.isCommonPicture(e)).map(e=>e.url || e.fileUrl);
        currentIndex = this.currentViewPhotos.findIndex(e=>e==currentFile.url)
      }else{
        this.currentViewPhotos = [currentFile.url]
      }
      this.$nextTick(()=>{
        this.$refs.previewFileRef.$viewer.view(currentIndex);
      })
    },


    closeVideo(e){
      let videoDom = document.getElementById('video-play')
      if(!videoDom.contains(e.target)){
        this.playVideoSrc = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.img-group{
  cursor: pointer;
  padding: 10px 15px;
  img,video{
    width: 80px;
    height: 80px;
  }
}
.video-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 99;
  background: rgba(0,0,0,0.45);
}
.file-content{
  display: none;
}
.video{
  z-index: 100;
  -o-object-fit: fill;
  object-fit: cover;
  height: 540px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.resource-item{
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>