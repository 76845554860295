<template>
  <!--  回复  -->
  <el-dialog title="回复" :visible.sync="_show">
    <el-form :model="commentForm" ref="commentForm" :rules="commentFormRules" size="medium" label-width="110px">
      <el-form-item label="内容" prop="content">
        <el-input type="textarea" maxlength="80"
                  v-model="commentForm.content">
        </el-input>
      </el-form-item>
      <el-form-item label="图片/视频">
        <d-upload :file-list.sync="commentForm.contentResource"></d-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="default" size="medium" @click="_show=false">取消</el-button>
      <el-button type="primary" size="medium" @click="confirmComment">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import DUpload from "@/components/d-upload";
export default {
  name: "flow-comment",
  components: {DUpload},
  props:{
    show:{
      type:Boolean,
      default(){
        return false
      }
    },
    // 当前回复的这个主体
    current:{
      type:Object,
      default() {
        return {}
      }
    },
    // 顶级评论编号 评论的是节点则为0
    topId:{
      type:Number,
      default(){
        return 0
      }
    },

    // 流程节点编号
    nodeId:{
      type:Number,
      default(){
        return 0
      }
    },

    // 当前工单编号
    orderId:{
      type:Number,
      default(){
        return 0
      }
    }
  },
  data(){
    return{
      commentForm:{
        content:'',
        contentResource:[]
      },
      commentFormRules:{
        content:this.$formValidator.empty('内容')
      }
    }
  },
  watch:{
    'show'(val){
      if(val){
        this.commentForm={
          content:'',
         contentResource:[]
        }
      }
    }
  },
  computed:{
    _show:{
      get(){
        return this.show;
      },
      set(val){
        this.$emit("update:show",val)
      }
    }
  },

  methods:{
    confirmComment(){
      this.$refs.commentForm.validate((valid)=>{
        if(valid){
          this.$api.tour.addNodeComment({
            nodeDetailId:this.nodeId, // 节点编号
            orderId:this.orderId, // 订单编号
            parentId:this.topId?this.current.id:0, // 父级评论编号 如果评论的是节点则传0
            topId:this.topId, // 顶级评论编号 如果评论的是节点则传0
            ...this.commentForm
          }).then(res=>{
            this._show=false;
            this.$emit('refresh');
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>