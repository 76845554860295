<template>
  <div class="worker-info-wrapper">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="worker-info_row">
          <label>工单号：</label>
          <span>{{info.orderNo}}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="worker-info_row">
          <label>状态：</label>
          <span :class="[info.status==2?'colorGrey':'','detail-status']">{{info.statusName}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="worker-info_row">
          <label>报修人员：</label>
          <span v-if="!info.isAnonymous">{{`${info.createUser&&info.createUser.username} / ${info.createDept && info.createDept.deptName}`}}</span>
          <span v-else>***</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="worker-info_row">
          <label>联系电话：</label>
          <span>{{info.createUser && info.createUser.mobile}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="worker-info_row">
          <label>巡场报修时间：</label>
          <span>{{info.createTime}}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="worker-info_row">
          <label>巡场区域：</label>
          <span>{{info.areaName}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="worker-info_row">
          <label>受理部门/人员：</label>
          <span v-if="info.handleType==1">{{`${ info.handleUser.username} / ${info.handleDept && info.handleDept.deptName}` }}</span>
          <span v-else>{{`${info.handleDept && info.handleDept.deptName}` }}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="worker-info_row">
          <label>问题类型：</label>
          <span>{{info.typeName}}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="worker-info_row">
          <label>预期完成时间：</label>
          <span>{{info.estimateDate}}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="worker-info_row">
          <label>详细位置：</label>
          <span>{{info.address}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="worker-info_row">
          <label>巡场描述：</label>
          <span>{{info.description}}</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "order-info",
  props:{
    info:{
      type:Object,
      default:{

      }
    }
  }
}
</script>

<style scoped lang="scss">
.worker-info_row{
  >label{
    width: 110px;
    text-align: right;
  }
}
.detail-status{
  color: $subjectColor;
}
</style>